<template>
    <div>
        <ValidationObserver ref="filterForm">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="form.semester_id"
                                :validate-error="errors[0]"></semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12">
                    <ValidationProvider name="file" :rules="'required'" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('excel_file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file v-model="form.file" :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"></b-form-file>
                                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{
                                    $t('browse') }}</b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            <b-badge variant="primary" class="mt-1">
                                {{ $t('student_number_long') }},
                                {{ $t('course_code') }}
                            </b-badge>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" @click="save">
                        {{ $t('import') }}
                    </b-button>
                </b-col>
                <CommonModal ref="errorsModal" size="lg">
                    <template v-slot:CommonModalTitle>
                        <div>{{ $t('invalid_values').toUpper() }}</div>
                    </template>
                    <template v-slot:CommonModalContent>
                        <b-table striped hover :items="errors" :fields="fields">
                            <template #cell(message)="row">
                                <span class="badge badge-danger">{{ $t('api.' + row.item.message) }}</span>
                            </template>
                        </b-table>
                    </template>
                </CommonModal>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import MakeUpExamService from '@/services/MakeUpExamService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        SemestersSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            form: {},
            errors: [],
            fields: [
                {
                    label: this.$t('line'),
                    key: 'line',
                },
                {
                    label: this.$t('student_number'),
                    key: 'student_number',
                },
                {
                    label: this.$t('course_code'),
                    key: 'course_code',
                },

                {
                    label: this.$t('message'),
                    key: 'message',
                }
            ],
        }
    },
    methods: {
        async save() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                this.errors = []
                const formData = new FormData();
                formData.append('semester_id', this.form.semester_id);
                formData.append('file', this.form.file);

                MakeUpExamService.makeupExamsImport(formData)
                    .then((response) => {
                        this.$toast.success(this.$t('api.SUCCESS'))

                        if (response?.data?.data?.errors?.length > 0) {
                            this.errors = response.data.data.errors
                            this.$refs.errorsModal.$refs.commonModal.show()
                        }
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
